<!--  -->
<script>
import { downloadApi } from '../../../utils/api'

export default {
  data() {
    return {
      android: {
        info2: {},
        info4: {}
      },
      ios: {
        info2: {},
        info4: {}
      }
    }
  },
  created() {
    this.getDownloadUrl(2)
    this.getDownloadUrl(4)
  },
  methods: {
    async getDownloadUrl(type) {
      const { code, data } = await downloadApi({ type })
      if (code === 1) {
        data.android && (this.android['info' + type] = data.android)
        data.ios && (this.ios['info' + type] = data.ios)
      }
    },
    download(url) {
      let a = /iPhone/i.test(navigator.userAgent)
      if (a) {
        return
      }
      this.downloadFile(this.$URL + url)
    },
    downloadFile(url) {
      let link = document.createElement('a')
      link.href = url
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
</script>

<template>
  <main>
    <div class="content">
      <div class="logo">
        <img src="../image/ele_icon.png" alt="" />
      </div>
      <!-- <p class="ver">版本号 xxxx</p> -->
      <div class="download-title">数智电工APP下载</div>
      <div class="btn btn-type">
        <div v-if="android.info4.url">
          <el-button type="primary" round @click="download(android.info4.url)">
            <img class="system-icon" id="android" src="../image/android.png" alt="" />
            <span class="text">{{ `Android下载 (测试版 v${android.info4.version ?? '0.0.0'})` }}</span>
          </el-button>
        </div>
        <div v-if="android.info2.url">
          <el-button type="primary" round @click="download(android.info2.url)">
            <img class="system-icon" id="android" src="../image/android.png" alt="" />
            <span class="text">{{ `Android下载 (正式版 v${android.info2.version ?? '0.0.0'})` }}</span>
          </el-button>
        </div>
      </div>
      <div class="btn btn-type">
        <div v-if="ios.info4.url">
          <el-button type="primary" round @click="download(ios.info4.url)">
            <img class="system-icon" id="ios" src="../image/ios.png" alt="" />
            <span class="text">{{ `IOS下载 (测试版 v${ios.info4.version ?? '0.0.0'})` }}</span>
          </el-button>
        </div>
        <div v-if="ios.info2.url">
          <el-button type="primary" round @click="download(ios.info2.url)">
            <img class="system-icon" id="ios" src="../image/ios.png" alt="" />
            <span class="text">{{ `IOS下载 (正式版 v${ios.info2.version ?? '0.0.0'})` }}</span>
          </el-button>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
@import '../css/index.css';
</style>
